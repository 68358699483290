import { Typography, Box } from '@mui/material'
import MPPortrait from '../Assets/MPPortrait.png'
import TypeWriterEffect from 'react-typewriter-effect'

export default function Title() {
    return (
        <div>
            <Box sx={{ textAlign: 'center' }}>
                <Box component='img' src={MPPortrait} sx={{ width: 300, borderRadius: '50%', mt: 4, mx: 6, boxShadow: '1px 1px 5px #e7e7e7, -1px -1px 5px #afafaf' }} />
                <Box sx={{ mx: 6, my: 4 }}>
                    <Typography variant='h2' component='h1' sx={{ fontWeight: 500, mb: 0.5 }}>MARTI PURULL</Typography>
                    {/* <Typography variant='h3' component='h2' sx={{ fontSize: 40 }}>Full Stack Developer</Typography> */}
                    <TypeWriterEffect
                        textStyle={{ fontFamily: 'Epilogue', fontSize: 40, fontWeight: 200, textAlign: 'center' }}
                        startDelay={100}
                        text='Full Stack Developer'
                        typeSpeed={70}
                        cursorColor='rgb(175, 175, 175)'
                    />
                </Box>
            </Box>
        </div>
    )
}
