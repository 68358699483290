import Title from './Title';
import Intro from './Intro';
import Tech from './Tech';
import Portfolio from './Portfolio';
import Contact from './Contact';
import { Box } from '@mui/material';

export default function Layout() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Title />
            <Intro />
            <Tech />
            <Portfolio />
            <Contact />
        </Box>


    )
}
