import './App.css';
import Layout from './Components/Layout';
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

const appTheme = createTheme({
  palette: {
    background: {
      default: 'rgb(175, 175, 175)'
    }
  },
  typography: {
    fontFamily: 'Epilogue',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  }
})

function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <Layout />

    </ThemeProvider>
  );
}

export default App;
