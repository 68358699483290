import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { FaUsers } from "react-icons/fa";

export default function Intro() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 6 }}>
            <Box sx={{ width: '50vw' }}>
                <Typography variant='h4' sx={{ fontSize: 32, borderTop: '1px solid white', py: 6, textAlign: 'center' }}>About Me</Typography>
                <Typography variant='body1' mb={1}>In 2020, I moved to Berlin to make music after years in the corporate world. The pandemic showed me two things: that I loved working remotely and that I loved to code.</Typography>
                <Typography variant='body1' my={1}>Two years, three courses and many lines of code later, I graduated as a Full Stack Developer at Epicode (fka Strive School).</Typography>
                <Typography variant='body1' my={1}>
                    In May 2022, I was offered a position at{' '}
                    <a style={{ textDecoration: 'none', color: 'inherit' }} href='mailto:martipurull@gmail.com' target='_blank' rel="noreferrer">
                        Connexin
                    </a>
                    {' '}as a Software Specialist. Here are some of my milestones of the past two years:
                </Typography>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <ArrowCircleUpIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Promoted to mid-level after successfully leading a project as a Junior engineer within 3 months at the company.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <GroupAddIcon />
                        </ListItemIcon>
                        <ListItemText>
                            Managed migration of 11,000 customers from external systems to our composite micro-services system post acquisition of a competitor.
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <FaUsers size={22} />
                        </ListItemIcon>
                        <ListItemText>
                            Skilled in coordinating with various departments to ensure efficient work completion aligned with stakeholder needs.
                        </ListItemText>
                    </ListItem>
                </List>
            </Box>
        </Box>
    )
}
