import { Box, Grid, Typography } from "@mui/material"
import FTO from '../Assets/ftoScreenshot.png'
import Whatsapp from '../Assets/whatsappScreenshot.jpeg'


export default function Portfolio() {
    return (
        <Grid container spacing={4} mb={6}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box sx={{ width: '50vw' }}>
                        <Typography variant='h4' sx={{ fontSize: 32, borderTop: '1px solid white', py: 6, textAlign: 'center' }}>From My Portfolio</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box className='contactItem' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box component='img' src={FTO} sx={{ maxWidth: 450, height: 300, objectFit: 'cover', mb: 4, borderRadius: '6px', boxShadow: '1px 1px 5px #e7e7e7, -1px -1px 5px #afafaf' }} />
                    <Box sx={{ width: '50%' }}>
                        <Typography variant='h5' my={1}>find-the-others</Typography>
                        <Typography variant='body2' my={1}>find-the-others is a cross between a project management app and a gig finder with a social network twist for musicians.</Typography>
                        <Typography variant='body2' my={1}>Inside, musicians can organise their projects with an easy, drag-and-drop, kanban-style dashboard. They can also connect with musicians they know by inviting them to signup.</Typography>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
                <Box className='contactItem' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box component='img' src={Whatsapp} sx={{ maxWidth: 450, height: 300, objectFit: 'cover', mb: 4, borderRadius: '6px', boxShadow: '1px 1px 5px #e7e7e7, -1px -1px 5px #afafaf' }} />
                    <Box sx={{ width: '50%' }}>
                        <Typography variant='h5' my={1}>Whatsapp Clone</Typography>
                        <Typography variant='body2' my={1}>An emulation of Whatsapp that enables real-time communication between its users.</Typography>
                        <Typography variant='body2' my={1}>Users can connect with others and have private or group conversations. Log in can be implemented directly on the app or via Facebook authentication.</Typography>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
