import { Box, Typography, Grid } from '@mui/material'
import Github from '../Assets/githubLogo.svg'
import LinkedIn from '../Assets/linkedinLogo.svg'
import Email from '../Assets/emailLogo.svg'

export default function Contact() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
            <Box sx={{ width: '50vw' }}>
                <Typography variant='h4' sx={{ fontSize: 32, borderTop: '1px solid white', py: 6, textAlign: 'center' }}>Contact</Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} xl={4}>
                        <a style={{ textDecoration: 'none', color: 'inherit' }} href='mailto:martipurull@gmail.com' target='_blank' rel="noreferrer">
                            <Box className='contactItem' sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='img' src={Email} sx={{ width: 50, mr: 2 }} />
                                <Typography variant='body1'>martipurull@gmail.com</Typography>
                            </Box>
                        </a>
                    </Grid>
                    <Grid item xs={12} xl={4}>
                        <a style={{ textDecoration: 'none', color: 'inherit' }} href='https://github.com/martipurull' target='_blank' rel="noreferrer">
                            <Box className='contactItem' sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='img' src={Github} sx={{ width: 50, mr: 2 }} />
                                <Typography variant='body1'>github.com/martipurull</Typography>
                            </Box>
                        </a>
                    </Grid>
                    <Grid item xs={12} xl={4}>
                        <a style={{ textDecoration: 'none', color: 'inherit' }} href='https://linkedin.com/in/martipurull' target='_blank' rel="noreferrer">
                            <Box className='contactItem' sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box component='img' src={LinkedIn} sx={{ width: 50, mr: 2 }} />
                                <Typography variant='body1'>linkedin.com/in/martipurull</Typography>
                            </Box>
                        </a>
                    </Grid>
                </Grid>
                <Typography variant='body2' mt={8} mb={1.5} textAlign='center'>&copy; <span style={{ fontWeight: 'bold' }}>marti</span>.codes {new Date().getUTCFullYear()}</Typography>
            </Box>
        </Box>
    )
}
