import { Box, Typography, Grid } from "@mui/material"
import CSSLogo from '../Assets/CSS.png'
import ExpressLogo from '../Assets/expressjs.jpg'
import HTMLLogo from '../Assets/HTML.png'
import NextJSLogo from '../Assets/NextJS icon.svg'
import JestLogo from '../Assets/Jest Logo Transparent.png'
import JSLogo from '../Assets/Javascript.png'
import BootstrapLogo from '../Assets/Bootstrap.png'
import TailwindCSSLogo from '../Assets/Tailwind CSS Logo.svg.png'
import MUILogo from '../Assets/MaterialUI.png'
import MongoLogo from '../Assets/MongoDB.png'
import FaunaLogo from '../Assets/fauna-seeklogo.svg'
import NodeLogo from '../Assets/NodeJS.png'
import PostgreLogo from '../Assets/PostgreSQL.png'
import ReactLogo from '../Assets/React.png'
import ReduxLogo from '../Assets/Redux.png'
import TSLogo from '../Assets/Typescript.png'
import { styled } from '@mui/material/styles'

const TechItem = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 100
}))

export default function Tech() {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 6, textAlign: 'center' }}>
            <Box sx={{ width: '50vw' }}>
                <Typography variant='h4' sx={{ fontSize: 32, borderTop: '1px solid white', py: 6 }}>My Tech Stack</Typography>
                <Grid container spacing={6}>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={NextJSLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>NextJS</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={ReactLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>React</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={JestLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Jest</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={TSLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Typescript</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={ReduxLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Redux</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={NodeLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>NodeJS</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={ExpressLogo} sx={{ width: 75, mb: 1, borderRadius: 4 }} />
                            <Typography variant='caption'>Express</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={FaunaLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Fauna DB</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={MongoLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>MongoDB</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={PostgreLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>PostgreSQL</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={BootstrapLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Bootstrap</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={TailwindCSSLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Tailwind CSS</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={MUILogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Material UI</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={HTMLLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>HTML</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={CSSLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>CSS</Typography>
                        </TechItem>
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TechItem className='techItem'>
                            <Box component='img' src={JSLogo} sx={{ width: 75, mb: 1 }} />
                            <Typography variant='caption'>Javascript</Typography>
                        </TechItem>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
